import {useEffect, useState} from "react";
import querystring from "querystring";

// const API_URL = 'http://localhost:8080'
const API_URL = 'https://api.everysport.com/v1'
let API_KEY = localStorage.getItem("apikey")
let sharedCache = {}
let leaguesLookup = {}

async function callApi(path, queryObj) {
    const url = path + '?' + querystring.encode(queryObj)

    const response = await fetch(API_URL + url + '&apikey=' + API_KEY)
    let data = await response.json();
    if(data.status === 'error') {
        if(data.message)
            throw Error(data.message)
        else if (data.messages)
            throw Error(data.messages.join(' '))
        else
            throw Error("Unknown error.")
    }
    return data
}


function shared(key, handler) {
    if (!sharedCache[key]) {
        sharedCache[key] = handler()
    }
    return sharedCache[key]
}

function getSports() {
    return shared('sports', () => callApi('/sports'))
}



function getLeaguesForSport(sportId) {
    return shared('leaguesForSport' + sportId, () =>
        callApi('/leagues', {
            sport: sportId,
            limit: 1000,
            activeDate: new Date().toISOString().substring(0, 10) + '-',
            secretIgnoreCache: 1338
        })
            .then((data) => {
                if (!data.leagues) {
                    data.leagues = []
                }
                for (const league of data.leagues) {
                    leaguesLookup[league.id] = league
                }

                return data;
            })
    )
}

function getLeague(leagueId) {
    return shared('league' + leagueId, async () => {
        if (!leaguesLookup[leagueId]) {
            let result = await callApi('/leagues/' + leagueId);
            await getLeaguesForSport(result.league.sport.id)
        }
        return leaguesLookup[leagueId]
    })
}

// HOOK stuff:

function useAsyncThing(handler) {
    const [state, set_state] = useState({})
    useEffect(() => {
        set_state({loading: true})
        handler((partialData) => set_state({loading: true, data: partialData}))
            .then(data => {
                set_state({data});
            })
            .catch(e => {
                set_state({error: e})
            })
    }, [])
    return state
}

export function useSports() {
    return useAsyncThing(() => getSports())
}

export function useLeaguesForSport(sportId) {
    return useAsyncThing(async (partial) => {
        const sportsResponse = await getSports();
        const sport = sportsResponse.sports.find(s => s.id === parseInt(sportId))
        partial({sport})
        const response = await getLeaguesForSport(sportId)
        response.sport = sport
        return response;
    })
}

export function useLeague(leagueId) {
    return useAsyncThing(() => getLeague(leagueId))
}

export function useLogoUpload() {
    const [state, set_state] = useState({})

    async function uploadLogo(team, file) {
        set_state({loading: true})

        const formData = new FormData()
        formData.append('file', file);
        const res = await fetch(`${API_URL}/teams/${team.id}/uploadLogo?apikey=${API_KEY}`, {
            method: 'POST',
            body: formData
        })
        const body = await res.json()
        console.log(body)
        team.logo = body.team.logo
        set_state({loading: false, team})
    }

    return [uploadLogo, state]
}

export function useApikey() {
    let [apikey, set_apikey] = useState(API_KEY);

    return [apikey, (apikey) => {
        sharedCache = {}
        leaguesLookup = {}
        API_KEY = apikey
        localStorage.setItem("apikey", apikey)
        set_apikey(apikey);
    }]
}