import React, {useRef} from 'react';
import './App.css';
import {BrowserRouter as Router} from "react-router-dom";
import {Route, Switch} from "react-router";
import IndexPage from "./IndexPage";
import SportPage from "./SportPage";
import LeaguePage from "./LeaguePage";
import {useApikey} from './apiHooks'
import {Button, Segment, Header, Form} from "semantic-ui-react";

function Main() {
    const [apikey, set_apikey] = useApikey()
    const inputEl = useRef(null);

    if (apikey) {
        return <div style={{padding: 14}}>
            <Segment>
                <Switch>
                    <Route exact path='/' component={IndexPage}/>
                    <Route path='/sport/:sportId' component={SportPage}/>
                    <Route path='/league/:leagueId' component={LeaguePage}/>
                </Switch>
            </Segment>
            <Segment>
                Apikey: {apikey} <Button onClick={() => set_apikey(null)}>clear</Button>
            </Segment>
        </div>
    } else {
        return <div style={{padding: 14}}>
            <Segment>
                <Header>Apikey krävs</Header>
                <Form onSubmit={() => set_apikey(inputEl.current.value)}>
                    <Form.Field>
                        <label>Apikey</label>
                        <input ref={inputEl}/>
                    </Form.Field>
                    <Button type="submit">Skicka</Button>
                </Form>
            </Segment>
        </div>
    }
}

function App() {
    return (
        <Router>
            <Main/>
        </Router>
    );
}

export default App;
