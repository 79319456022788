import React from "react";
import {Loader, Message} from "semantic-ui-react";

export default ({loading, data, error}, handler) => {
    if (loading)
        return <Loader active/>
    if (error)
        return <Message
            icon='warning'
            header='Kunde inte ladda.'
            content={error.message}
        />
    if (data)
        return handler(data)
    return <div>Nothing</div>
}