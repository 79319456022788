import React from 'react';
import loadingBox from './loadingBox';
import {Link} from "react-router-dom";
import {useSports} from "./apiHooks";


export default function IndexPage() {
    const apiResponse = useSports()

    return loadingBox(apiResponse, ({sports}) => {
        return <div>
            {sports.map(s => <div key={s.id}>
                <Link to={`/sport/${s.id}`}>{s.name}</Link>
                </div>
            )}
        </div>
    })
}



//
