import React from 'react';
import loadingBox from './loadingBox';
import {Link} from "react-router-dom";
import _ from "lodash";
import {useLeaguesForSport} from "./apiHooks";
import {Breadcrumb, Header, Table} from "semantic-ui-react";


function LeagueGroup({teamClass, leagues}) {
    if (!leagues)
        return <></>

    const nowDate = new Date().toISOString().substring(0, 10)
    return <div>
        <Header>{teamClass}</Header>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Liga</Table.HeaderCell>
                    <Table.HeaderCell>Start Datum</Table.HeaderCell>
                    <Table.HeaderCell>Lag med Loggor</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {leagues.map(l => {
                        const teams = l.teams
                        const teamsWithLogo = teams.filter(t => t.logo)
                        const done = teamsWithLogo.length === teams.length
                        const date = l.startDate.substring(0, 10)

                        const started = nowDate >= date
                        return <Table.Row key={l.id}
                                          positive={done}
                                          negative={!done && started}
                                          warning={!done && !started}
                        >
                            <Table.Cell>
                                <Link
                                    to={`/league/${l.id}`}>{l.name}</Link>
                            </Table.Cell>
                            <Table.Cell>
                                {date}
                            </Table.Cell>
                            <Table.Cell>
                                {teamsWithLogo.length}/{teams.length}
                            </Table.Cell>


                        </Table.Row>;
                    }
                )}

            </Table.Body>
        </Table>
        <br/>
    </div>
}

export default function SportPage({match}) {
    const {params: {sportId}} = match

    let apiResponse = useLeaguesForSport(sportId);

    const sections = [
        {key: 'Start', content: "Start", as: Link, to: '/'},
        {key: 'Sport', content: apiResponse?.data?.sport?.name},
    ]

    return <div>
        <Breadcrumb icon='right angle' sections={sections} size='huge'/>
        <Header>{}</Header>
        {loadingBox(apiResponse, ({leagues}) => {
            const grouped = _.groupBy(leagues, 'teamClass')
            if(leagues.length === 0) {
                return "Inga ligor för den här sporten."
            }
            return <>
                <LeagueGroup teamClass='Herrar' leagues={grouped['MEN']}/>
                <LeagueGroup teamClass='Damer' leagues={grouped['WOMEN']}/>
                <LeagueGroup teamClass='Mix' leagues={grouped['MIXED']}/>
            </>
        })}
    </div>
}


// async function onChange(e) {
//     console.log(e.currentTarget.files)
//
//     const formData = new FormData()
//
//     formData.append('file', e.currentTarget.files[0]);
//
//     const res = await fetch(`${API_URL}/teams/test?apikey=4d0aab0be14ad340f724033d6320f973`, {
//         method: 'POST',
//         body: formData
//     })
//
//     const body = await res.json()
//     console.log(body)
// }
//
