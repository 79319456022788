import React from "react";
import {useLeague, useLogoUpload} from "./apiHooks";
import loadingBox from "./loadingBox";
import {Link} from "react-router-dom";
import {Breadcrumb, Icon, Table, Message, Loader} from "semantic-ui-react";


function TeamRow({team}) {
    let [uploadLogo, {loading}] = useLogoUpload();

    return <Table.Row negative={!team.logo} positive={!!team.logo}>
        <Table.Cell>
            <div style={{height: 30}}>
            {!loading && team.logo && <a href={team.logo} target='_blank' rel="noopener noreferrer"><img alt={"logo"} src={team.logo} height="30"/></a>}
            <Loader inline indeterminate active={loading} size={"small"}/>
            </div>
        </Table.Cell>
        <Table.Cell>
            {team.name}
        </Table.Cell>
         <Table.Cell>
             {team.id}
        </Table.Cell>
        <Table.Cell>

            {<input type="file"
                    onChange={(e) => {
                        uploadLogo(team, e.currentTarget.files[0]);
                        e.currentTarget.value = null
                    }}
                    style={{visibility: loading ? 'hidden' : null}}/>
            }

        </Table.Cell>
    </Table.Row>
}


export default function LeaguePage({match}) {
    const {params: {leagueId}} = match

    let apiResponse = useLeague(leagueId);
    let league = apiResponse?.data;
    const sport = league?.sport
    const sections = [
        {key: 'Start', content: "Start", as: Link, to: '/'},
        {key: 'Sport', content: sport?.name, as: Link, to: '/sport/' + sport?.id},
        {key: 'League', content: (league?.name || '') + ' (' + leagueId + ')'},
    ]

    return <div>
        <Breadcrumb icon='right angle' sections={sections} size='huge'/>

        {loadingBox(apiResponse, (league) => {
            const teams = league.teams
            const teamsWithLogo = league.teams.filter(t => t.logo)
            const done = teamsWithLogo.length === teams.length

            return <div>
                <br/>
                <Message positive={done} negative={!done}>
                    Lag med loggor: {teamsWithLogo.length} / {teams.length}
                    {done && <Icon name={"check"} color={"green"}/>}
                </Message>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Logga</Table.HeaderCell>
                            <Table.HeaderCell>Lagnamn</Table.HeaderCell>
                            <Table.HeaderCell>Lagid</Table.HeaderCell>
                            <Table.HeaderCell>Filuppladdning</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {league.teams.map(t => <TeamRow team={t} key={t.id}/>)}
                    </Table.Body>
                </Table>
            </div>
        })}
    </div>
}